import React from 'react'

import Layout from '../components/layout'
import ContactForm from '../components/ContactForm/contactForm'
import AnimatedHr from '../components/animatedHr'

const ContactUs = () => (
  <Layout>
    <div className="headerImage service">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>Contact Us</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>
              Your Sheffield Experts in repairing electronic devices from
              catering equipment to laptops.
            </p>
          </div>
        </div>
      </div>
    </div>
    <section>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <p>
              <strong>Opening Times</strong>
              <br></br>Tuesday to Friday 9am to 4.30 pm.{' '}
            </p>
            <p>
              <strong>Phone</strong>
              <br></br>0114 244 7257{' '}
            </p>
            <p>
              <strong>Email</strong>
              <br></br>
              <a href="mailto:repairs@nova-electronics.co.uk">
                repairs@nova-electronics.co.uk
              </a>{' '}
            </p>
            <p>
              If you would like to find out more about our products or services
              email us using the form below or phone 0114 244 7257 NOVA
              electronics, 700-702 Attercliffe Road, Sheffield, South Yorkshire
              S9 3RP
            </p>
            <p>
              Our pride comes from saving you money by enabling you to repair
              instead of replace, giving your familiar device a new lease of
              life. We are committed to helping local businesses do well and to
              providing a personal, professional service to all our customers.
            </p>
            <p>
              Faults with electronic devices can, in many cases, be easily fixed
              and save you money. However, if this is not possible, we will
              discuss your options with you.
            </p>
          </div>
          <div className="six columns">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9516.590070215638!2d-1.431398!3d53.394301!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x45844cc2e19c1928!2sNOVA+electronics!5e0!3m2!1sen!2suk!4v1541004702371"
              style={{ width: '100%', height: '350px', border: 'none' }}
            />
          </div>
        </div>
      </div>
    </section>
    <ContactForm />
  </Layout>
)

export default ContactUs
