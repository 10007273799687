import React from "react"
import "./contactForm.css"
import AnimatedHr from "../animatedHr"
import { Form, Field } from "react-final-form"
import axios from "axios"

export default function contactForm() {
  return (
    <Form
      onSubmit={values => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/

        const errors = {}

        const requiredFields = ["name", "phone", "text", "email"]

        requiredFields.forEach(field => {
          const fieldName = field.toLowerCase()

          if (!values[fieldName]) {
            errors[fieldName] = "Required"
          }

          if (
            fieldName === "email" &&
            values.email &&
            !emailRegex.test(String(values.email).toLowerCase())
          ) {
            errors.email = "Invalid Email"
          }

          if (
            fieldName === "phone" &&
            values.phone &&
            !phoneRegex.test(String(values.phone).toLowerCase())
          ) {
            errors.phone = "Invalid Number"
          }
        })

        if (Object.keys(errors).length) return errors

        if (values.sweet) {
          //honey pot has been filled in, this is a bot, don't actually send the email
          return
        }

        axios.post(
          `${process.env.MAIL_SERVER}`,
          {
            from: "Nova Electronics Website <website@nova-electronics.co.uk>",
            to: "repairs@nova-electronics.co.uk",
            subject: `Message from ${values.name}`,
            text: values.text,
            replyTo: values.email,
            domain: "nova-electronics.co.uk",
            html: `<p>${values.text}</p>`,
            autoResponse: {
              from: "Nova Electronics <website@nova-electronics.co.uk>",
              to: values.email,
              replyTo: "repairs@nova-electronics.co.uk",
              subject: "Message Recieved",
              text:
                "Thank you for getting in touch with Nova Electronics, we will reply shortly. Kind Regards, Nova Electronics",
              html:
                "<p>Thank you for getting in touch with Nova Electronics, we will reply shortly.</p> <p> Kind Regards,</p> <p>Nova Electronics</p> ",
            },
          },
          {
            headers: {
              Authorization: `Basic ${
                typeof window === "undefined"
                  ? ""
                  : window.btoa(`Nova Electronics:b70a5d0da6e57c1b39ea9111515faf4a`)
                }`,
            },
          }
        )
      }}
      render={({ handleSubmit, submitSucceeded }) => (
        <form onSubmit={handleSubmit} noValidate>
          <section>
            <div className="container contact-form">
              <div className="row ">
                <h2 className="center-text">
                  Get In Touch
                  <AnimatedHr />
                </h2>
                <div className="row">
                  <div className="six columns">
                    <Field name="name">
                      {({ input }) => (
                        <input type="text" {...input} placeholder="Name" />
                      )}
                    </Field>
                  </div>
                  <div className="six columns">
                    <Field name="phone">
                      {({ input }) => (
                        <input type="number" {...input} placeholder="Phone" />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row">
                  <div className="twelve columns">
                    <Field name="email">
                      {({ input }) => (
                        <input type="email" {...input} placeholder="Email" />
                      )}
                    </Field>
                  </div>
                </div>
                <div className="row">
                  <div className="twelve columns">
                    <Field name="text">
                      {({ input }) => <textarea {...input} />}
                    </Field>
                  </div>
                  <Field name="sweet">
                    {({ input }) => (
                      <input
                        type="text"
                        {...input}
                        style={{ display: "none" }}
                      />
                    )}
                  </Field>
                </div>
                <div className="row">
                  <div
                    className="twelve columns"
                    style={{ textAlign: "center" }}
                  >
                    {submitSucceeded ? (
                      <div className="contact-confirmed">
                        <p>
                          Your message has been sent, we will get in touch
                          shortly.
                        </p>
                      </div>
                    ) : (
                        <button className="button" type="submit">
                          Send Message
                      </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      )}
    />
  )
}
